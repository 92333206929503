<template>
  <div :id="id" class="carousel slide mb-0 w-100" data-bs-ride="false">
    <div class="carousel-inner">
      <template> {{ (active = 'active') }}</template>
      <template v-for="(standImg, index) in gallery" :key="index" >
        <div v-if="isImage(standImg)" :class="`carousel-item ${active}`">
          <template> {{ (active = '') }} </template>
          <div class="centered-content">
            <img
              
              class="rounded img-thumbnail d-block"
              :src="standImg"
              alt="No Image"
              :style="`max-height: ${height}; width: auto !important`"
            />

          </div>
        </div>
        <div v-else-if="isVideo(standImg)" :class="`carousel-item ${active}`">
          <template> {{ (active = '') }} </template>
          <div class="centered-content p-4 m-4">
            <video
            id="video"
            class="rounded img-thumbnail d-block"
              :style="`max-height: ${height}; width: auto !important`"
              controls 
              muted
            >
            <source :src="standImg" type="video/mp4">
          </video>

          </div>
        </div>

      </template>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      :data-bs-target="'#' + id"
      data-bs-slide="prev"
      @click.prevent="disableVideo()"
    >
      <span
        class="carousel-control-prev-icon bg-black"
        aria-hidden="true"
      ></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      :data-bs-target="'#' + id"
      data-bs-slide="next"
      @click.prevent="disableVideo()"
    >
      <span
        class="carousel-control-next-icon bg-black"
        aria-hidden="true"
      ></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
  <div id="carouselButtons"></div>
</template>

<script setup>
definePageMeta({
  name: "Carousel",
});

const props = defineProps({
  id: String,
  gallery: Array,
  height: {
    type: String,
    default: "200px",
  },
});

function isImage(image) {
  return /\.(jpe?g|png|gif|bmp|webp|svg|avif|heic|heif|avif)$/i.test(image);
}

function isVideo(video) {
  return /\.(mp4|webm|ogg|mp3|wav|flac|aac)$/i.test(video);
}

function disableVideo() {

  //get active carousel item, and pause the video
  try {

    const carousel = document.getElementById("galleryCarousel");
    const active = carousel.getElementsByClassName("carousel-item active")[0]
    const div = active.getElementsByTagName("div")[0]
    const videos = div.getElementsByTagName("video")
    if (videos.length > 0) {
      const video = videos[0]
      video.pause();
    }
  }
  catch (err) { console.log(err) }
}
</script>
